<template>
  <div class='indexbox'>
    <div class="searchbox">
      <div class="btnbox">
        <el-button type="primary" @click="show=true">Invite New Users</el-button>
        <el-button type="primary" @click="logout">Log Out</el-button>
      </div>
      <div class="tablebox">
        <div class="draggable" v-loading='loading'>
          <el-table :data="tableData" stripe>
            <el-table-column prop="email" label="USER" width='200' fixed align='center' show-overflow-tooltip />
            <el-table-column prop="create_time" label="DATE" width='200' align='center' show-overflow-tooltip />
            <el-table-column prop="is_main" label="Administrator Or Not" min-width='200' align='center' show-overflow-tooltip>
              <template slot-scope="scope">
                <p v-if="scope.row.is_main==1">Yes</p>
                <p v-if="scope.row.is_main==0">No</p>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="STATUS" min-width='120' align='center' show-overflow-tooltip>
              <template slot-scope="scope">
                <p v-if="scope.row.status==0">Pending</p>
                <p v-else>Opened</p>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="OPERATION" min-width='120' align='center' show-overflow-tooltip>
              <template slot-scope="scope">
                <el-button v-hasPermi="['us']" type="primary" size="small" @click="setdata(scope.row)">PERMISSIONS</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination @current-change='pagechage' background layout="total,prev, pager, next" v-show="total>0" :current-page.sync="page" :page-size="10" :total="total" />
      </div>
    </div>

    <el-dialog title="Invite New Users" :visible.sync="show" width="30%">
      <el-form ref="form" :model="form" :rules="rules" class="formbox">
        <el-form-item prop="email">
          <span class="form_tit">Email</span>
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item class="btnbox">
          <el-button type="primary" @click.native.prevent="onSubmit" :loading='btn_loading'>Submit</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import { removeToken } from "@/utils/auth";
  export default {
    computed: {
      ...mapGetters(['api_id']),
    },
    data() {
      return {
        ruleList: [],
        loading: false,
        total: 0,
        page: 1,
        tableData: [],
        show: false,
        btn_loading: false,
        form: {
          email: '',
          api_id: this.$store.state.user.api_id,
        },
        rules: {
          email: [
            { required: true, trigger: "blur", message: 'Please Enter' }
          ],
        },
        dataform: {
          email: '',
          id: '',
          rules: [],
        },
        datarules: {
          rules: [
            { required: true, trigger: "blur", message: 'Please select' }
          ],
        },
        datashow: false,
        data_loading: false,
      };
    },
    methods: {
      getlist() {
        this.loading = true
        this.$post({
          url: "/app/api_user/userList",
          data: {
            page: this.page,
            api_id: this.$store.state.user.api_id
          },
          success: (res) => {
            this.tableData = res.data.data
            this.total = res.data.total
            this.page = res.data.current_page
            setTimeout(() => {
              this.loading = false
            }, 200);
          },
          tip: () => {
            this.loading = false
          },
        })
      },
      pagechage(page) {
        this.page = page
        this.tableData = []
        this.getlist()
      },
      // 退出登录
      logout() {
        this.$confirm(`Are You Sure You Want To Log Out ?`, 'Tips', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.$post({
            url: "/app/member/logout",
            success: () => {
              removeToken()
              sessionStorage.removeItem("activeIndex")
              sessionStorage.removeItem('vuex')
              this.$message({
                type: 'Success',
                message: 'Log out'
              });
              setTimeout(() => {
                this.$router.replace('login')
              }, 1500);
            },
            tip: () => {
            },
          })
        }).catch(() => { });
      },
      // 邀请好友
      onSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.btn_loading = true
            this.$post({
              url: "/app/member/sendInviteEmail",
              data: this.form,
              success: (res) => {
                this.$message({
                  message: 'Success',
                  type: 'success'
                });
                this.form = {
                  email: '',
                  api_id: '',
                }
                this.show = false
                setTimeout(() => {
                  this.btn_loading = false
                }, 1500);
              },
              tip: () => {
                setTimeout(() => {
                  this.btn_loading = false
                }, 1500);
              },
            })
          }
        });
      },
      setdata(data) {
        this.$router.push(`rules?id=${data.id}`)
      }
    },
    created() {
      this.getlist()
    },
  }
</script>
<style lang='scss' scoped>
  .indexbox {
    width: 100%;
    height: 100%;
    .searchbox {
      width: 100%;
      padding: 10px 20px 0px;
      background: #fff;
      border-bottom: 1px solid #babdcd;
    }
    .btnbox {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .tablebox {
      width: 100%;
      padding: 10px 10px 20px;
    }
    .formbox {
      /deep/.el-form-item {
        margin-bottom: 30px !important;
        .el-select,
        .el-date-editor {
          width: 100%;
        }
        .el-form-item__content {
          width: 100%;
          .el-button {
            width: 100%;
            height: 48px;
            background: $color;
            border-radius: 5px;
            border-color: $color;
          }
        }
      }
    }
  }
</style>